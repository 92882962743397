@import "../../../../public/assets/css/variables.scss";

.track{
    &__background{
        background: linear-gradient(-60deg, #3143e8 50%, #2cceff 100%);
        // padding: 8rem 0 6rem 0;
    }
    &__button{
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        line-height: 1;
        text-align: center;
        padding: 18px 30px;
        border: 1px solid white;
        border-radius: 6px;
        outline: 0 none;
        position: relative;
        z-index: 1;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}